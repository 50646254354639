<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333">销售流水</p>
      </div>
      <div class="rightSide_inner_date">
        <el-date-picker
          v-model="requestParams.chooseData"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tableData: [],
      requestParams: {
        chooseData: "",
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.rightSide {
  width: 1120px;
  height: 786px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  &_inner {
    width: 1100px;
    height: 714px;
    &_title {
      width: 1100px;
      height: 68px;
    }
    &_date {
      width: 1100px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
</style>
